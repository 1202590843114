import { defineComponent, ref, computed } from 'vue'
import { Download as DownloadIcon } from '@element-plus/icons'

import DsView from '@/components/DsView'
import { Model, Reference } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import { useStore } from '@/store'
import { getPdf } from '@/components/PdfViewer'
import { formatTimeSpent } from '@/utils/datetimes'

import {
  availableProductSize,
  availableProductColors,
  availableProductHeel,
} from '@/assets/enums'

import { ElTooltip } from 'element-plus'
import {
  ChatSquare as CommentIcon,
  Warning as WarningIcon,
} from '@element-plus/icons'

const recipeModel: Model = {
  type: 'object',
  indent: false,
  properties: {
    master_recipe: { type: 'string', label: 'Recipe Group', required: true },
    product: { type: 'string', label: 'Name', required: true },
    owner: {
      type: 'uuid',
      model: 'users',
      label: 'Owner',
      optionLabel: (user: any) => `${user.name} ${user.lastname}`,
    },
    workshop: {
      type: 'string',
      label: 'Workshop',
      required: true,
    },
    order_id: {
      type: 'uuid',
      model: 'orders',
      label: 'Order',
      required: true,
      optionLabel: (order: any) =>
        `${order.order_number} ${order.product ? '- ' + order.product : ''}`,
    },
    description: {
      type: 'string',
      label: 'Description',
    },
    size: {
      type: 'string',
      enum: availableProductSize,
      allowCreate: true,
      label: 'Size',
    },
    heel: {
      type: 'string',
      enum: availableProductHeel,
      allowCreate: true,
      label: 'Heel',
    },
    color: {
      type: 'string',
      allowCreate: true,
      enum: availableProductColors,
      label: 'Color',
    },
    picture: {
      type: 'file',
      label: 'Picture',
    },
    comment: {
      type: 'string',
      label: 'Comment',
    },
    steps: {
      type: 'array',
      propertiesLayout: 'section',
      label: 'Steps',
      itemKeyName: 'step_name',
      items: {
        type: 'object',
        label: 'Step',
        properties: {
          step_name: { type: 'string', label: 'Step Name', required: true },
          owner: {
            type: 'uuid',
            model: 'users',
            label: 'Owner',
            required: true,
            optionLabel: (user: any) => `${user.name} ${user.lastname}`,
          },
          description: { type: 'string', label: 'Description' },
          comment: { type: 'string', label: 'Comment' },
          depend_on: {
            type: 'string',
            label: 'Depend on',
            enum: [
              {
                value: 1,
                label: 'Write the name(s) of the previous step(s)',
                disabled: true,
              },
            ],
            multiple: true,
            allowCreate: true,
          },
          components: {
            type: 'array',
            itemKeyName: 'alias',
            items: {
              type: 'object',
              label: 'Component',
              properties: {
                alias: {
                  type: 'string',
                  label: 'Component Alias',
                },
                component_id: {
                  type: 'uuid',
                  model: 'components',
                  label: 'Component',
                  required: true,
                },
                quantity: {
                  type: 'number',
                  label: 'Quantity',
                  required: true,
                  suffix: new Reference(
                    'local',
                    'component_id',
                    'components',
                    'unit'
                  ),
                },
              },
            },
            label: 'Components',
          },
          pictures: {
            type: 'file',
            label: 'Picture',
          },
        },
      },
    },
  },
}

const columns: Column[] = [
  { key: 'identifier', header: 'PO #' },
  { key: 'product', header: 'Product' },
  {
    key: 'comment',
    header: '',
    icon: true,
    formatter: (value) => {
      return typeof value === 'string' ? (
        <ElTooltip
          trigger="hover"
          content={value}
          v-slots={{
            default: () => <CommentIcon style="height: 20px;color:grey" />,
          }}
        />
      ) : (
        ''
      )
    },
    sortable: false,
  },
  {
    key: 'order_id',
    header: 'Client',
    formatter: (value: unknown, relations: any) => {
      if (
        relations['orders'] &&
        typeof value === 'string' &&
        relations['clients']
      ) {
        const order = relations['orders'][value]
        const client =
          order && order.client_id in relations['clients']
            ? relations['clients'][order.client_id]
            : null
        if (client) {
          return `${client.name} ${
            client.lastname && typeof client.lastname === 'string'
              ? `${client.lastname.charAt(0)}.`
              : ''
          }`
        }
      }
      return ''
    },
  },
  {
    key: 'created_at',
    header: 'Received',
    defaultSort: 'desc',
    formatter: (value) =>
      formatTimeSpent(typeof value === 'string' ? value : null),
  },
  {
    key: 'workflow',
    header: 'Active tasks',
    formatter: (tasks: any, relations: any) => {
      return (
        <table
          class="task_infos"
          style="position:relative;border-spacing: 0;display:block"
        >
          {tasks &&
            Object.values(tasks).map((task: any) => {
              if (task.status === 'active') {
                let task_owner
                if (task.owner && relations['users']) {
                  task_owner = relations['users'][task.owner]
                }
                let timeSinceTaskStarted: number | Date = new Date(0)
                for (const dependencie of task.upstream || []) {
                  timeSinceTaskStarted =
                    new Date(tasks[dependencie].status) > timeSinceTaskStarted
                      ? new Date(tasks[dependencie].status)
                      : timeSinceTaskStarted
                }
                const elapsedTime =
                  timeSinceTaskStarted.getTime() !== 0
                    ? formatTimeSpent(timeSinceTaskStarted.getTime())
                    : null
                return (
                  <tr
                    style={task.error ? 'background-color: #fcedeb;' : ''}
                    class="subrows"
                  >
                    <td style="min-width: 20px;">
                      {task.error && (
                        <ElTooltip
                          trigger="hover"
                          content={task.error}
                          v-slots={{
                            default: () => (
                              <WarningIcon style="height: 20px;color:#f56c6c;vertical-align: middle;" />
                            ),
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <span style="vertical-align: middle;">{task.label}</span>
                    </td>

                    <td>
                      {task_owner
                        ? `${task_owner.name} ${task_owner.lastname}`
                        : ''}
                    </td>
                    <td>{elapsedTime || 'Not started yet'}</td>
                  </tr>
                )
              }
            })}
        </table>
      )
    },
  },
]

const defaultOrder = (): any => ({
  id: crypto.randomUUID(),
  product: '',
  owner: '',
  order_id: '',
  steps: [],
})

export default defineComponent({
  name: 'Recipes',
  components: { DsView },
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.user)

    return () => (
      <DsView
        view={{
          collection: 'recipes',
          workflow: true,
          title: 'My tasks',
          model: recipeModel,
          columns: columns,
          headerKey: 'product',
          history: false,
          customRelations: ['clients'],
          rowKey: 'id',
          mainAction: {
            icon: DownloadIcon,
            label: 'Bolla PDF',
            callback: getPdf,
          },
          secondaryActions: ['edit'],
        }}
        defaultValue={defaultOrder}
        frontendDefaultFilter={(recipe: any) =>
          !!recipe.order_id &&
          recipe.workflow &&
          Object.values(recipe.workflow).find(
            (step: any) =>
              user.value?.uid === step.owner && step.status === 'active'
          )
        }
        backendDefaultFilter={{
          type: 'group',
          condition: 'AND',
          filters: [
            {
              type: 'filter',
              property: 'order_id',
              operator: 'IS_NOT_EMPTY',
              value: '',
            },
          ],
        }}
      />
    )
  },
})
